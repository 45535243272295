/* --------------------------------------------------------------------------
   .sec-history
--------------------------------------------------------------------------- */

.sec-history {
  position: relative;
  z-index: 1;
  padding: 30px 0;
  background-color: #5d2185;

  @include media-breakpoint-up(md) {
    padding: 60px 0 77px;
  }

  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    background-size: cover;
    content: '';

    @include media-breakpoint-up(md) {
      background-position: 50% 50%;
    }
  }

  &::before {
    bottom: 100%;
    padding-top: 19.17%;
    background-image: url('/assets/images/history_bg_top.png');

    @include media-breakpoint-up(md) {
      padding-top: 0;
      height: 386px;
      background-image: url('/assets/images/history_bg_top_md.png');
    }
  }

  &::after {
    top: 100%;
    padding-top: 27.2%;
    background-image: url('/assets/images/history_bg_bottom.png');

    @include media-breakpoint-up(md) {
      padding-top: 0;
      height: 353px;
      background-image: url('/assets/images/history_bg_bottom_md.png');
    }
  }

  &__inner {
    @include media-breakpoint-up(md) {
      position: relative;
    }
  }
}

/* --------------------------------------------------------------------------
   .sec-history .deco
--------------------------------------------------------------------------- */

.sec-history .deco {
  @include media-breakpoint-up(md) {
    position: absolute;
    bottom: 100%;
    display: inline-block;
    background-size: contain;
    opacity: 0;
  }

  &--01 {
    @include media-breakpoint-up(md) {
      right: -192px;
      margin-bottom: 176px;
      width: 595px;
      height: 305px;
      background-image: url('/assets/images/deco_img01_md.png');
      transform: scale(0);
    }
  }

  &.is-active {
    @include media-breakpoint-up(md) {
      opacity: 1;
    }

    &.deco--01 {
      @include media-breakpoint-up(md) {
        transition: transform .35s $ease-out-quart;
        transition-delay: .7s;
        transform: scale(1);
      }
    }

    &.deco--02 {
      @include media-breakpoint-up(md) {
        transition: transform .5s $ease-out-quart, opacity .1s;
        transition-delay: 1.1s;
        transform: translateX(0);
      }
    }
  }
}

/* --------------------------------------------------------------------------
   .sec-history .block
--------------------------------------------------------------------------- */

.sec-history .block {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }

  .m-heading {
    opacity: 0;
    transition: transform 1.2s $ease-out-quart, opacity .6s;

    @include media-breakpoint-up(md) {
      transition-delay: .8s;
      transform: translate3d(-20px, 0, 0);
    }

    &.is-active {
      opacity: 1;

      @include media-breakpoint-up(md) {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__text {
    margin: 28px 0 0;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.87;
    opacity: 0;
    transition: transform 1.2s $ease-out-quart, opacity .6s;

    @include media-breakpoint-up(md) {
      margin: 10px 0 0;
      width: 50%;
      text-align: left;
      line-height: 2.4;
      transition-delay: .8s;
      transform: translate3d(20px, 0, 0);
    }

    &.is-active {
      opacity: 1;

      @include media-breakpoint-up(md) {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__button {
    margin: 28px 0 0;
    text-align: center;
    opacity: 0;
    transition: transform 1.2s $ease-out-quart, opacity .6s;

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -530px;
      padding: 14px 0 0;
      transition-delay: .8s;
      transform: translate3d(-20px, 0, 0);
    }

    &.is-active {
      opacity: 1;

      @include media-breakpoint-up(md) {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
