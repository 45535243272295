/* --------------------------------------------------------------------------
   .sec-schedule
--------------------------------------------------------------------------- */

.sec-schedule {
  position: relative;
  z-index: 3;
  margin: -100px 0 0;
  transform: skewY(9.1deg);

  @include media-breakpoint-up(md) {
    margin: -370px 0 0;
  }

  &__inner {
    position: relative;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 200px;

    @include media-breakpoint-up(md) {
      padding-top: 140px;
      padding-bottom: 600px;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
    }

    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: url('/assets/images/pattern03.png');
      background-size: 25px;
      transform: skewY(-9.1deg);
      transform-origin: 0 0;
    }
  }

  &__body {
    transform: skewY(-9.1deg);
  }

  &__lead {
    margin: 25px 0 0;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.53;

    @include media-breakpoint-up(md) {
      margin: 48px 0 0;
    }
  }
}

/* --------------------------------------------------------------------------
   .sec-schedule .detail
--------------------------------------------------------------------------- */
.sec-schedule .detail {
  position: relative;
  margin: 30px 0 0;
  border: 5px solid #ffe124;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    margin: 75px 0 0;
    border-width: 8px;
  }

  &__icon {
    position: absolute;
    top: -28px;
    left: -27px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #ffe124;

    @include media-breakpoint-up(md) {
      top: -55px;
      left: -50px;
      flex-direction: row;
      width: 130px;
      height: 130px;
    }
  }

  .year {
    color: #5d2185;
    line-height: 1;

    &--01 {
      margin: 2px 0 0;
      letter-spacing: -.03em;
      font-size: 2.1rem;
      font-family: $f-viga;

      @include media-breakpoint-up(md) {
        letter-spacing: -.01em;
        font-size: 3.2rem;
      }
    }

    &--02 {
      margin: 1px 0 0;
      font-weight: 600;
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        position: relative;
        right: -5px;
        margin: 5px 0 0;
        font-size: 1.4rem;
      }
    }
  }

  &__inner {
    padding: 10px 30px;
    opacity: 0;
    transition: opacity .6s;

    @include media-breakpoint-up(md) {
      padding: 27px 50px 25px;
    }

    &.is-active {
      opacity: 1;
    }
  }
}

/* .sec-schedule .detail .block
----------------------------------------------------------------- */

.sec-schedule .detail .block {
  padding: 15px 0;
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    display: flex;
    padding: 27px 35px;
  }

  + .block {
    border-top: 1px solid #cacaca;
  }

  &__heading {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .date,
    span {
      position: relative;
      padding: 0 0 0 22px;

      @include media-breakpoint-up(md) {
        padding: 0 0 0 40px;
      }
    }

    span {
      @include media-breakpoint-up(md) {
        margin: 0 0 0 50px;
        min-width: 88px;
      }
    }

    .date::before,
    span::before {
      position: absolute;
      top: 12px;
      left: 0;
      background-size: contain;
      content: '';
      transform: translateY(-50%);
    }

    .date {
      @include media-breakpoint-up(md) {
        min-width: 228px;
      }
    }

    .date::before {
      width: 16px;
      height: 16px;
      background-image: url('/assets/images/icon_clock.svg');

      @include media-breakpoint-up(md) {
        width: 22px;
        height: 22px;
      }
    }

    span::before {
      width: 15px;
      height: 15px;
      background-image: url('/assets/images/icon_school.svg');

      @include media-breakpoint-up(md) {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__body {
    margin: 5px 0 0;
    font-weight: 600;

    @include media-breakpoint-up(md) {
      margin: 0 0 0 67px;
    }
  }
}
