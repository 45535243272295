/* --------------------------------------------------------------------------
   .sec-report
--------------------------------------------------------------------------- */

.sec-report {
  position: relative;
  z-index: 1;
  margin: -30px 0 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin: -155px 0 0;
  }

  &::before {
    position: absolute;
    top: -5%;
    left: 0;
    z-index: 1;
    border-width: 8vw 40vw;
    border-style: solid;
    border-color: transparent transparent transparent #dc3b5a;
    content: '';

    @include media-breakpoint-up(md) {
      top: -13.8%;
      min-width: 1280px;
      border-width: 6vw 31.6vw;
    }
  }

  &__inner {
    position: relative;
    padding: 60px 0 100px;

    @include media-breakpoint-up(md) {
      padding: 200px 0 450px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transform: skewY(9.1deg);

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 105%;
      background: url('/assets/images/pattern01.png');
      background-size: 7px;
      content: '';
      transform: skewY(-9.1deg);
      transform-origin: 0 0;

      @include media-breakpoint-up(md) {
        background-size: 12px;
      }
    }
  }

  &__body {
    position: relative;
  }

  &__lead {
    margin: 30px 0 0;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.53;

    @include media-breakpoint-up(md) {
      margin: 45px 0 0;
      line-height: 2.4;
    }
  }

  &__detail {
    margin: 34px 0 0;

    @include media-breakpoint-up(md) {
      display: flex;
      margin: 63px -38px 0;
    }
  }

  &__button {
    margin: 35px 0 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 93px 0 0;
    }
  }
}

/* --------------------------------------------------------------------------
   .sec-report .unit
--------------------------------------------------------------------------- */

.sec-report .unit {
  display: flex;
  flex-direction: column;
  opacity: 0;

  @include media-breakpoint-up(md) {
    display: block;
    padding: 0 38px;
    width: 25%;
    transform: translate3d(-20px, 0, 0);
  }

  + .unit {
    margin: 38px 0 0;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      display: block;
      backface-visibility: hidden;
    }
  }

  &__link:hover {
    .unit__image::before {
      @include media-breakpoint-up(md) {
        transition: transform .3s $ease-out-quint;
      }
    }

    figure {
      @include media-breakpoint-up(md) {
        transition: transform .3s $ease-out-quint;
        transform: translate(-4px, -4px);
      }
    }

    .unit__heading {
      @include media-breakpoint-up(md) {
        color: #5d2185;
        transition: color .3s;
        backface-visibility: hidden;
      }
    }
  }

  &__image {
    position: relative;
    width: 42%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }

    &::before {
      position: absolute;
      top: 10px;
      right: -5px;
      padding-top: 89%;
      width: 100%;
      background: url('/assets/images/pattern02.png');
      background-size: 2px;
      content: '';

      @include media-breakpoint-up(md) {
        top: 20px;
        right: -10px;
        padding-top: 88%;
        background-size: 4px;
        opacity: .6;
        transition: transform .4s $ease-out-quint;
      }
    }

    figure {
      position: relative;
      padding-top: 91.91%;
      background-position: 50% 50%;
      background-size: cover;

      @include media-breakpoint-up(md) {
        transition: transform .4s $ease-out-quint;
      }
    }
  }

  &__body {
    margin: 0 0 0 25px;
    width: 50%;

    @include media-breakpoint-up(md) {
      margin: 35px 0 0;
      width: auto;
    }
  }

  &__category {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      display: inline-block;
      padding: 4px 6px;
      background-color: #ffe124;
      letter-spacing: .05em;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1;

      @include media-breakpoint-up(md) {
        padding: 4px 15px;
        font-size: 1.2rem;
      }
    }

    time {
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
      }
    }
  }

  &__heading {
    margin: 10px 0 0;
    font-size: 1.5rem;
    line-height: 1.4;

    @include media-breakpoint-up(md) {
      margin: 18px 0 0;
      line-height: 1.73;
    }
  }
}

// fade animation

$delay: .2;

@for $i from 0 through 4 {
  .unit--0#{$i}.is-active {
    opacity: 1;
    transition: transform 1s $ease-out-quart, opacity .6s;
    transition-delay: #{.1 * $i + $delay}s;

    @include media-breakpoint-up(md) {
      transform: translate3d(0, 0, 0);
    }
  }
}

/* .sec-report .unit .tag
----------------------------------------------------------------- */

.sec-report .unit .tag {
  display: none;

  @include media-breakpoint-up(md) {
    position: relative;
    display: block;
    margin: 18px 0 0;
    padding: 18px 0 0 20px;
    border-top: 1px solid #cacaca;
    letter-spacing: .02em;
    font-size: 1.2rem;
  }

  &::before {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 20px;
      left: 0;
      margin: 1px 0 0;
      width: 14px;
      height: 14px;
      background-image: url('/assets/images/icon_tag.svg');
      background-size: contain;
      content: '';
    }
  }

  &__link {
    &:hover {
      @include media-breakpoint-up(md) {
        text-decoration: underline;
      }
    }
  }

  span {
    @include media-breakpoint-up(md) {
      margin: 0 0 0 -2px;
    }
  }
}
