/* --------------------------------------------------------------------------
   .slider
--------------------------------------------------------------------------- */

.slider {
  @include media-breakpoint-up(md) {
    display: none;
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &.swiper-container {
    position: static;
  }
}

.slider > .swiper-pagination-bullets {
  position: absolute;
  bottom: 55px;
  z-index: 3;
  padding: 0 25px;
  text-align: right;

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    outline: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .8);

    &.swiper-pagination-bullet-active {
      background-color: #ffe124;
    }
  }
}
