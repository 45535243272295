/* --------------------------------------------------------------------------
   .key-visual
--------------------------------------------------------------------------- */

.key-visual {
  position: relative;

  @include media-breakpoint-up(md) {
    height: 100vh;
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
  }

  &::before {
    right: 0;
    z-index: 2;
    border-width: 0 315px 50px 0;
    border-style: solid;
    border-color: transparent #5d2185 transparent transparent;
    content: '';

    @include media-breakpoint-up(md) {
      border-width: 0 750px 115px 0;
    }
  }

  &::after {
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url('/assets/images/pattern04.png');
    background-size: 8px;
    pointer-events: none;
  }
}

/* --------------------------------------------------------------------------
   .key-visual .textarea
--------------------------------------------------------------------------- */

.key-visual .textarea {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);

  @include media-breakpoint-up(md) {
    margin: -75px 0 0;
  }

  &__inner {
    position: relative;
    display: inline-block;

    &::before {
      position: absolute;
      top: -33px;
      left: 40px;
      width: 74px;
      height: 65px;
      background-image: url('/assets/images/icon_flag.svg');
      background-size: contain;
      content: '';
      opacity: 0;
      transform: translate3d(0, -30px, 0) rotate(-10deg);
      transform-origin: bottom left;
      animation: flag .2s $ease-out-back 2s 1 forwards;

      @include media-breakpoint-up(md) {
        top: -63px;
        left: 125px;
        width: 128px;
        height: 113px;
      }
    }
  }

  &__lead,
  &__heading {
    position: relative;
    color: #ffe124;
    text-align: center;
    font-family: $f-viga;
  }

  &__lead {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    letter-spacing: 0;
    font-size: 2rem;
    line-height: 1;
    transform: skewX(-14deg);

    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }

    span {
      display: inline-block;
      letter-spacing: -4.5px;

      @include media-breakpoint-up(md) {
        letter-spacing: -9px;
      }
    }
  }

  &__heading {
    span {
      display: inline-block;
      margin: 8px 0 0;
      letter-spacing: -.02em;
      font-size: 5.1rem;
      line-height: 1;
      transform: skewX(-14deg);

      @include media-breakpoint-up(md) {
        margin: 28px 0 0;
        font-size: 12rem;
      }
    }
  }

  &__image {
    display: inline-block;
    opacity: 0;
    animation: hide 1.6s $ease-out-back 2.4s 1 forwards;

    @include media-breakpoint-up(md) {
      margin: 23px 0 0;
    }

    img {
      width: 202px;
      height: 53px;

      @include media-breakpoint-up(md) {
        width: 354px;
        height: 88px;
      }
    }
  }
}

// animation

$delay: .2;

@for $i from 0 through 18 {
  .key-visual .textarea__lead span:nth-child(#{$i}) {
    transform: translate3d(0, -100px, 0);
    animation: lead .05s $ease-out-sine forwards;
    animation-delay: #{.05 * $i + $delay}s;
  }
}

.key-visual .textarea__inner .textarea__heading {
  opacity: 0;
  transform: translate3d(-100%, 0, 0) skewX(-20deg);
  animation: heading .33s $ease-out-back forwards;
  animation-delay: 1.5s;
}

@keyframes lead {
  0% {
    transform: translate3d(0, -100px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes heading {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) skewX(-10deg);
  }

  50% {
    opacity: 1;
    transform: translate3d(5%, 0, 0) skewX(-20deg);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) skewX(0deg);
  }
}

@keyframes flag {
  0% {
    opacity: 0;
    transform: translate3d(0, -30px, 0) rotate(-20deg);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate(-10deg);
  }
}

@keyframes hide {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* --------------------------------------------------------------------------
   .key-visual .mv-video
--------------------------------------------------------------------------- */

.key-visual .mv-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  // &.is-hidden {
  //   opacity: 0;
  //   transition: opacity .4s $ease-in-quart, transform .4s $ease-in-quart;
  //   transform: scale(1.2);
  // }
}
