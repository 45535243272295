/* --------------------------------------------------------------------------
   .sec-intro
--------------------------------------------------------------------------- */

.sec-intro {
  padding: 140px 0 280px;
  background-image: url('/assets/images/intro_bg.jpg');
  background-position: 50% 50%;
  background-size: cover;

  @include media-breakpoint-up(md) {
    padding: 335px 0 925px;
    background-image: url('/assets/images/intro_bg_md.jpg');
  }

  &__lead {
    margin: 30px 0 0;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.53;

    @include media-breakpoint-up(md) {
      margin: 55px 0 0;
    }
  }

  &__button {
    margin: 20px 0 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 33px 0 0;
    }
  }
}

/* --------------------------------------------------------------------------
   .sec-intro .list
--------------------------------------------------------------------------- */

.sec-intro .list {
  margin: 35px 0 0;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 45px -23px 0;
  }

  &__item {
    visibility: hidden;
    opacity: 0;
    // transition: opacity 1s;

    @include media-breakpoint-up(md) {
      margin: 40px 0 0;
      padding: 0 23px;
      width: 33.33%;
    }

    img {
      @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
      }
    }

    + .list__item {
      margin: 15px 0 0;

      @include media-breakpoint-up(md) {
        margin: 40px 0 0;
      }
    }
  }

  &__link {
    display: block;

    picture {
      display: block;
      // opacity: 0;
      // transition: opacity 1s;

      @include media-breakpoint-up(md) {
        position: relative;
        overflow: hidden;
      }
    }

    img {
      @include media-breakpoint-up(md) {
        transition: transform .4s $ease-out-quart;
      }
    }
  }

  .heading {
    position: relative;
    top: -20px;
    z-index: 1;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 0 0 23px;
    width: 90%;
    height: 40px;
    color: #5d2185;
    letter-spacing: .07em;
    font-weight: 600;
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
      top: -25px;
      padding: 0 0 2px 32px;
      width: 310px;
      height: 47px;
      font-size: 1.6rem;
      // transition: color .4s $ease-out-quart;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: #ffe124;
      content: '';
      transition: transform .7s $ease-out-quart;
      transform: translateX(-50%) skewX(14deg) scale3d(0, 1, 0);

      @include media-breakpoint-up(md) {
        transform: translateX(-50%) skewX(23deg) scale3d(0, 1, 0);
      }
    }

    // .text {
    //   opacity: 0;
    //   transition: transform .7s $ease-out-quart, opacity .4s;
    //   transform: translate3d(-10px, 0, 0);
    // }

    .overlay {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        display: inline-block;
        width: 0;
        height: 100%;
        background-color: #5d2185;
        transition: width .4s $ease-out-quart;
        transform: skewX(23deg);
      }
    }
  }
}

/* hover
----------------------------------------------------------------- */

.sec-intro .list .list__link {
  &:hover {
    img {
      @include media-breakpoint-up(md) {
        transition: transform .4s;
        transform: scale(1.07);
      }
    }

    .heading {
      @include media-breakpoint-up(md) {
        color: #ffe124;
        transition: color .45s;
      }
    }

    .overlay {
      @include media-breakpoint-up(md) {
        left: 0;
        width: 100%;
        transition: width .45s;
      }
    }
  }

  .image,
  .heading {
    position: relative;
  }
}

/* animation
----------------------------------------------------------------- */

// .sec-intro .list .list__item.is-active {
//   visibility: visible;
//   opacity: 1;
//
//   picture {
//     opacity: 1;
//     transition-delay: .3s;
//   }
//
//   .heading::before {
//     transition-delay: 1.5s;
//     transform: translateX(-50%) skewX(23deg) scale3d(1, 1, 1);
//     transform-origin: left;
//   }
//
//   .text {
//     opacity: 1;
//     transition-delay: 2.4s;
//     transform: translate3d(0, 0, 0);
//   }
// }

.sec-intro .list .list__item.is-active {
  visibility: visible;
  opacity: 1;

  picture {
    // opacity: 1;
    // transition-delay: .3s;
    opacity: 0;
    animation: hide .1s ease 1s 1;
    animation-fill-mode: forwards;
  }

  .heading::before {
    opacity: 0;
    transform: translateX(-50%) skewX(23deg) scale3d(1, 1, 1);
    transform-origin: left;
    animation: hide .1s ease 1.5s 1;
    animation-fill-mode: forwards;
  }

  .text {
    opacity: 0;
    animation: hide .1s ease 1.5s 1;
    animation-fill-mode: forwards;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &__inner {
      display: inline-block;
      margin-left: -101%;
      width: 100%;
      height: 100%;
      background-color: #fff;
      // transform: translate3d(-101%, 0, 0);
      animation: mask 1s ease .5s 1;
      animation-fill-mode: forwards;
    }

    &--slant {
      transform: skewX(23deg);

      .mask__inner {
        background-color: #5d2185;
        animation: mask 1s ease 1s 1;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes mask {
  0% {
    margin-left: -101%;
    // transform: translate3d(-101%, 0, 0);
  }

  50% {
    margin-left: 0;
    // transform: translate3d(0, 0, 0);
  }

  55% {
    margin-left: 0;
    // transform: translate3d(0, 0, 0);
  }

  100% {
    margin-left: 101%;
    // transform: translate3d(101%, 0, 0);
  }
}

@keyframes hide {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
